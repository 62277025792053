import Header from "../components/Header/Header";
import Main from "../components/UI/Main/Main";
import CardGrid from "../components/IntroPage/CardGrid/CardGrid";
import FormIntro from "../components/IntroPage/FormIntro/FormIntro";
import Footer from "../components/Footer/Footer";
import IntroPage from "../components/TextIntroPages/IntroPage"
import MainDiv from "../components/ScientificWork/MainDiv";

const Mainpage = () => {
    return (
        <div id="page-wrap" >
            <Header />
            <Main>
                <IntroPage />
                <CardGrid />
                <FormIntro />
                <Footer />
            </Main>
        </div>
    )
}

export default Mainpage;
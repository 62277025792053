import { Fragment, useContext } from "react";
import classes from "./IntroPage.module.css";
import AuthContext from "../../store/auth-context"
import pdf from "./1-s2.0-S2772829323000462-main.pdf";

const ScText = () => {
    const ctx = useContext(AuthContext);
    const data = ctx.text.ScWork;

    return (
        <Fragment>
            <h1>{data.h1 }</h1>
            <div className={`${classes.pWrap} ${classes.pSpace}`}>
                <p>{data.p1 }</p>
                <p>{data.p2}</p>
                <p>{data.p3}</p>
                <p>{data.p4}</p>
                <br />
                <p >{data.p5}</p>
                <section className={classes.container}>
                    <h4>{ctx.text.Publication.sectionTitle}</h4>
                    <div >
                <p ><a  className={classes.linkA}
                  href="https://www.sciencedirect.com/science/article/pii/S2772829323000462" 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                    -{ctx.text.Publication.title}
                </a> :  <a  className={classes.dl}
                  href={pdf} download="Validation_of_AI-based_Software_Publication.pdf"
                >
                                       {ctx.text.Publication.download}
                </a>
                </p>
                <p>
               
                </p>
                <p> <a className={classes.linkA} href="https://doi.org/10.1101/2022.03.23.22272791"> -Medrxiv.org/content/10.1101/2022.03.23.22272791v2</a></p>

                    </div>
                </section>
            </div>
        </Fragment>
    )
}

export default ScText;
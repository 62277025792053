import { useContext } from "react"
import AuthContext from "../../store/auth-context"
import graphs from "../../Img/slide2.png"
import summary from "../../Img/AllergoEye-talk.png"
import classes from "./ScGraphs.module.css"

const ScGraphs = () => {
    const ctx = useContext(AuthContext)

    
    return (
        <section className={classes.wrap}>
            <div className={classes.container}>
                <div>
                    <img src={graphs} alt="Graphs" />
                </div>
                <div>
                    <img src={summary} alt="Graphs" />
                </div>
            </div>
            <div className={classes.pRight}>
                <p>{ctx.text.ScWork.prague}</p>
                <p>
                    {ctx.text.ScWork.footer}
                </p>
            </div>
        </section>
    )
}

export default ScGraphs